<template>
  <div id="footer">
    <v-container>
      <v-row class="footer-title justify-center">
        Zilant Oil Technologies
      </v-row>
      <v-row class="footer-text justify-center align-stretch font-weight-medium">
        <v-col cols="4" class="pr-5">
          <v-row no-gutters class="justify-center">
            <v-col cols="auto">
              <v-row no-gutters class="align-center mt-6">
                Наши контакты:
              </v-row>
              <v-row no-gutters class="mt-6">
                <img src="@/assets/footer2.svg" class="footer-icon" alt="">
                zilantoil@gmail.com
              </v-row>
              <v-row no-gutters class="mt-6">
                <img src="@/assets/footer3.svg" class="footer-icon" alt="">
                г. Казань, улица Лево-Булачная, дом 24, <br> помещение 1006
              </v-row>
              <v-row no-gutters class="mt-6">
                <img src="@/assets/footer4.svg" class="footer-icon" alt="">
                График работы: пн – пт с 8:00-17:00
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="4" class="image-wrapper">
          <v-row no-gutters class=""></v-row>
        </v-col>
        <v-col cols="4" class="contact-form align-self-center pl-16 pr-16">
          <div v-if="!isLoading && !isRequested">
            <v-row class="justify-center" fluid>
              <v-col cols="12">
                <v-row class="justify-center text-center font-weight-bold">
                  Оставьте ваши контакты, чтобы мы связались с Вами
                </v-row>
                <v-row class="mt-8">
                  <v-text-field
                    filled
                    light
                    background-color="#C4C4C4"
                    color="#343332"
                    class="contact-form-input text--white font-weight-medium"
                    placeholder="E-mail:"
                    outlined
                    v-model="email"
                  ></v-text-field>
                </v-row>
                <v-row>
                  <v-text-field
                    id="number-input"
                    filled
                    light
                    background-color="#C4C4C4"
                    color="#343332"
                    class="contact-form-input text--white font-weight-medium"
                    placeholder="Телефон:"
                    outlined
                    v-model="phoneNumber"
                    type="number"
                  ></v-text-field>
                </v-row>

                <v-row v-if="isError" class="justify-center mb-5">
                  <div class="red--text error-message text-center">
                    Хотя бы одно поле не должно быть пустым!
                  </div>
                </v-row>

                <v-row class="justify-center">
                  <v-btn
                    class="font-weight-medium"
                    background-color="#C4C4C4"
                    @click="sendEmail"
                  >Отправить
                  </v-btn>
                </v-row>
              </v-col>
            </v-row>
          </div>
          <div v-if="isLoading">
            <v-skeleton-loader
              v-bind="attrs"
              type="actions"
            ></v-skeleton-loader>
          </div>
          <div v-if="isRequested">
            <v-row class="font-weight-medium justify-center">Ваша заявка принята!</v-row>
          </div>
        </v-col>
      </v-row>
      <v-row class="footer-rights justify-center mt-16 pt-16 font-weight-bold">
        ©{{ new Date().getFullYear() }} Все права защищены
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      phoneNumber: '',
      email: '',
      isLoading: false,
      isRequested: false,
      isError: false
    }
  },
  methods: {
    async sendEmail() {

      if (!this.email && !this.phoneNumber) {
        this.isError = true;
        return;
      }

      this.isError = false;

      this.isLoading = true;

      const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
          "email": this.email,
          "phone": this.phoneNumber
        })
      }

      fetch(`https://${location.host}/email`, requestOptions)
        .then(response => {
          console.log(response)
          if (response.ok) {
            this.isRequested = true
            this.isLoading = false;
          } else {
            this.isLoading = false;
          }
        })
        .catch(err => {
          console.log('errr')
          console.error(err)
          this.isLoading = false;
        })
    }
  },
  mounted() {
    let footer = document.getElementById('footer')
    let wrapper = document.getElementsByClassName('content-wrapper')[0]

    window.addEventListener('scroll', () => {
      const footerHeight = footer.offsetHeight
      let toBottom = document.scrollingElement.scrollHeight - document.scrollingElement.clientHeight - document.scrollingElement.scrollTop
      wrapper.style.marginBottom = footerHeight + 'px';
      let opacityCoefficient = Math.max(0, 1 - toBottom / 450)
      footer.style.opacity = '' + Math.min(opacityCoefficient, 1)
    });
  }
}
</script>
<style scoped lang="scss">
@import "src/assets/styles/global.scss";

#footer {
  position: fixed;
  bottom: 0;
  width: 100vw;
  padding-bottom: 20px;
  background-color: #343332;
  color: #E5E5E5;
  letter-spacing: 1px;
  opacity: 0;
  z-index: -100;
}

.footer-title {
  margin-top: 50px;
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 40px;
}

.footer-text {
  font-size: 20px;

  @media screen and (max-width: $lg) {
    font-size: 13px;
  }

  @media screen and (max-width: $md) {
    font-size: 10px;
  }

  @media screen and (max-width: $sm) {
    font-size: 5px;
  }
}

.map-image {
  width: 100%;
}

.footer-icon {
  height: 25px;
  width: 25px;
  margin-right: 1rem;
}

.image-wrapper {
  background-image: url("../assets/map.png");
  background-size: cover;
  background-position: center center;
}

.footer-rights {
  font-size: 20px;
}

.contact-form-input {
  color: white !important;
}

.contact-form {
  font-weight: 500;
}
</style>
