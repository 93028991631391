<template>
  <div id="partners-section">
    <v-container>
      <v-row align="center" justify="center">
        <v-col class="partners-title mr-16 text-right" cols="3">
          <div>НАШИ ПАРТНЕРЫ</div>
          <div class="cover"></div>
          <div class="triangle"></div>
        </v-col>
        <v-col class="image-wrapper" cols="7">
          <img src="../assets/partners.png" alt="">
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "Partners"
}
</script>

<style scoped lang="scss">
#partners-section {
  background-color: rgb(255, 255, 255, .8);
  padding-bottom: 100px;
}

.partners-title {
  background-color: #48A97B;
  font-size: 20px;
  font-weight: bold;
  height: 120px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.triangle {
  position: absolute;
  content: '';
  right: -60px;
  top: 0;
  width: 0;
  height: 0;
  border-top: 60px solid transparent;
  border-left: 60px solid #48A97B;
  border-bottom: 60px solid transparent;
}

.cover {
  position: absolute;
  width: 100vw;
  height: 120px;
  background-color: #48A97B;
  left: -100vw;
}

.image-wrapper > img {
  width: 100%;
}

</style>
