<template>
  <div>
    <div class="content-wrapper">
      <MainSection/>
      <About/>
      <Developing/>
      <Modelling/>
      <Equipment/>
      <Technologies/>
      <Partners/>
      <Footer/>
    </div>
  </div>
</template>

<script>

import MainSection from "@/section/MainSection";
import About from "@/section/About";
import Developing from "@/section/Developing";
import Modelling from "@/section/Modelling";
import Equipment from "@/section/Equipment";
import Technologies from "@/section/Technologies";
import Partners from "@/section/Partners";
import Footer from "@/section/Footer";

export default {
  name: 'Main',
  components: {Footer, Partners, Technologies, Equipment, Modelling, Developing, About, MainSection}
}
</script>

<style>
.content-wrapper {
  position: relative;
  z-index: 100;
  margin-bottom: 450px;
  font-weight: 500;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>
