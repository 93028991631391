<template>
  <div id="developing-section">
    <v-container class="justify-center align-center">
      <v-row class="developing-container">
        <v-row class="justify-center align-center">
          <v-col>
            <div class="developing-title">ТЕХНОЛОГИИ И РЕАГЕНТЫ</div>
          </v-col>
        </v-row>
        <v-row class="justify-center">
          <v-col lg="6" md="12" sm="12" cols="12">
            <v-row>
              <v-col>
                <div class="card-logo">
                  <img src="../assets/logo1.svg" alt="">
                </div>
                <div class="card-text">ПАВ</div>
              </v-col>
              <v-col>
                <div class="card-logo">
                  <img src="../assets/logo2.svg" alt="">
                </div>
                <div class="card-text">ПОЛИМЕРЫ</div>
              </v-col>
              <v-col>
                <div class="card-logo">
                  <img src="../assets/logo3.svg" alt="">
                </div>
                <div class="card-text">РАСТВОРИТЕЛИ</div>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <div class="card-logo">
                  <img src="../assets/logo4.svg" alt="">
                </div>
                <div class="card-text">ТРАССЕРЫ</div>
              </v-col>
              <v-col>
                <div class="card-logo">
                  <img src="../assets/logo5.svg" alt="">
                </div>
                <div class="card-text">БИНАРНЫЕ СМЕСИ</div>
              </v-col>
              <v-col>
                <div class="card-logo">
                  <img src="../assets/logo6.svg" alt="">
                </div>
                <div class="card-text">ИМВ</div>
              </v-col>
            </v-row>
          </v-col>
          <v-col lg="6" md="10" sm="10" class="align-self-center justify-center mt-sm-10">
            <v-row>
              <v-row class="description align-center">
                <v-col class="pa-4 text-center" md="auto">
                  <div>
                    Наши реагенты применимы в широком спектре пластовых условий, обладают
                    долгосрочной стабильностью и устойчивостью к агрессивным средам
                  </div>
                  <Corners/>
                </v-col>
              </v-row>
              <v-row class="justify-center mt-10">
                <TechButton @click="downloadDoc"></TechButton>
              </v-row>
            </v-row>
          </v-col>
        </v-row>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import TechButton from "@/components/TechButton";
import Corners from "@/components/Corners";

export default {
  name: "Developing",
  components: {Corners, TechButton},
  methods: {
    async downloadDoc() {
      await fetch(`https://${location.host}/getDoc1`, {
        method: 'GET'
      }).then(response => {
        response.blob().then(blob => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.style.display = 'none';
          a.href = url;
          a.download = 'documentation.pdf';
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        })
      });
    }
  }
}
</script>

<style scoped lang="scss">
@import "src/assets/styles/global.scss";

#developing-section {
  background-color: rgb(255, 255, 255, .8);
}

.developing-title {
  font-size: 50px;
  color: #256B48;
  font-weight: 700;
  margin-bottom: 3rem;
  text-align: center;
}

.developing-container {
  padding-bottom: 100px;
}

.card-logo {
  display: flex;
  justify-content: center;

  img {

    @media screen and (max-width: $md) {
      height: 70px;
    }

    height: 120px;
    display: block;
    margin: auto;
  }
}

.card-text {
  margin-top: 1rem;
  color: #256B48;
  font-weight: 700;
  text-align: center;
}

.description {
  position: relative;
  font-size: 30px;
  line-height: 52px;

  @media screen and (max-width: $lg) {
    font-size: 25px;
  }

  @media screen and (max-width: $md) {
    font-size: 22px;
  }

  @media screen and (max-width: $sm) {
  }
}

</style>
