<template>
  <div id="technologies-section">
    <v-container>
      <v-row class="technologies-title justify-center">
        О КОМПАНИИ
      </v-row>
      <v-row justify="center" align="center">
        <v-col xl="auto" sm="auto" xs="12" cols="auto">
          <img src="../assets/about-img.png" alt="">
        </v-col>
        <v-col md="8" sm="12" xs="12" class="description" cols="9">
          Zilant Oil Technologies – это компания  предлагающая широкий спектр услуг для нефтяных и газовых компаний стран СНГ и Мира. В сферу наших компетенций входит полный цикл сопровождения проекта – от подбора метода до его успешного внедрения.
          Наши сотрудники – профессионалы с многолетним стажем. Мы работаем на лучшем оборудовании с лучшим программным обеспечением. Выбирая нас вы выбираете успех.
        <Corners/>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Corners from "@/components/Corners";
export default {
  name: "Technologies",
  components: {Corners}
}
</script>

<style scoped lang="scss">
@import "src/assets/styles/global.scss";

#technologies-section {
  background-color: rgb(255, 255, 255, .8);
  padding-top: 100px;
  padding-bottom: 100px;
}

.technologies-title {
  font-size: 50px;
  color: #256B48;
  font-weight: bold;
  margin-bottom: 3rem;
  text-align: center;
}

.description {
  position: relative;
  font-size: 22px;
  text-align: justify;
  padding: 2rem;

  @media screen and (max-width: $lg) {
    font-size: 18px;
  }

  @media screen and (max-width: $md) {
    font-size: 22px;
  }

  @media screen and (max-width: $sm) {
    font-size: 18px;
  }
}
</style>
