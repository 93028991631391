<template>
  <button class="tech-button" @click="$emit('click')">Получить техническое описание</button>
</template>

<script>
export default {
  name: "TechButton"
}
</script>

<style scoped lang="scss">

.tech-button {
  background-color: #29AB69;
  padding: 1rem;
  font-size: 18px;
  font-weight: bold;
  color: #FFF;
}

</style>
