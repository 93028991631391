<template>
  <div id="equipment-section">
    <v-container>
      <v-row class="modeling-title justify-center">
        <v-col class="ml-16" md="auto">
          ТЕХНОЛОГИЧЕСКОЕ СОПРОВОЖДЕНИЕ
        </v-col>
      </v-row>
      <v-row class="justify-center">
        <v-col class="img-wrapper1 hidden-sm-and-down"  cols="3">
          <img src="../assets/equipment1.svg" alt="">
        </v-col>
        <v-col cols="auto">
          <v-row class="description mt-0 pa-0 justify-center">
            <div style="position:relative;" class="pa-6">
              Добыча может быть эффективной в любое время года
              <Corners/>
            </div>
          </v-row>
          <v-row class="img-wrapper2 justify-center">
            <img src="../assets/equipment2.svg" alt="">
          </v-row>
        </v-col>
      </v-row>
      <v-row class="justify-center mt-10">
        <TechButton @click="downloadDoc"/>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Corners from "@/components/Corners";
import TechButton from "@/components/TechButton";
export default {
  name: "Equipment",
  components: {TechButton, Corners},
  methods: {
    async downloadDoc() {
      await fetch(`https://${location.host}/getDoc3`, {
        method: 'GET'
      }).then(response => {
        response.blob().then(blob => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.style.display = 'none';
          a.href = url;
          a.download = 'documentation.pdf';
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        })
      });
    }
  }
}
</script>

<style scoped lang="scss">
@import "src/assets/styles/global.scss";

#equipment-section {
  padding-top: 100px;
  padding-bottom: 100px;
  background-color: rgb(255, 255, 255, .8);
}

.modeling-title {
  font-size: 50px;
  color: #256B48;
  font-weight: 700;
  margin-bottom: 3rem;
  text-align: center;
}

.description {
  position: relative;
  font-size: 30px;
  padding: 2rem;


  @media screen and (max-width: $lg) {
    font-size: 25px;
  }

  @media screen and (max-width: $md) {
    font-size: 22px;
  }

  @media screen and (max-width: $sm) {
    font-size: 25px;
  }

  @media screen and (max-width: $xs) {
    font-size: 17px;
  }
}

.img-wrapper1 > img {
  width: 100%;
}

.img-wrapper2 > img {
  width: 50%;
}

</style>
