<template>
  <div id="main-section">
    <v-container class="pa-0" fluid>
      <v-row no-gutters id="header">

        <v-col cols="auto" class="align-self-start">
          <img src="../assets/header-logo.png" alt="" class="header-logo">
        </v-col>

        <v-col class="align-self-center">
          <v-row class="justify-space-around text-center" no-gutters>
            <v-col md="auto" class="align-self-center">
              <a href="#" @click="scrollToDevelopment">ТЕХНОЛОГИИ И РЕАГЕНТЫ</a>
            </v-col>

            <v-col md="auto" class="align-self-center">
              <a href="#" @click="scrollToModelling">МОДЕЛИРОВАНИЕ</a>
            </v-col>

            <v-col md="auto" class="align-self-center">
              <a href="#" @click="scrollToEquipment">ТЕХНОЛОГИЧЕСКОЕ СОПРОВОЖДЕНИЕ</a>
            </v-col>

            <v-col md="auto" class="align-self-center">
              <a href="#" @click="scrollToAboutUs">О КОМПАНИИ</a>
            </v-col>

            <v-col md="auto" class="align-self-center">
              <a href="#" @click="scrollToFooter">КОНТАКТЫ</a>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="company-name-section">
      <v-row class="justify-center fill-height align-center">
        <v-col md="auto">
          <v-row class="company-name-section__title justify-center">Zilant Oil Technologies</v-row>
          <v-row class="company-name-section__subtitle justify-center">МЫ ДЕЛАЕМ ДОБЫЧУ ЭФФЕКТИВНОЙ</v-row>
        </v-col>
      </v-row>
    </v-container>
    <div class="alert">
      <div class="text-block">
        ДОБЫЧА НЕФТИ НА 3 И 4 СТАДИЯХ РАЗРАБОТКИ <br> НЕВОЗМОЖНА БЕЗ АКТИВНОГО ПРИМЕНЕНИЯ МУН
      </div>
      <div class="triangle"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MainSection",
  methods: {
    scrollToDevelopment(event) {
      event.preventDefault()
      document.getElementById("developing-section").scrollIntoView({block: "center", behavior: "smooth"});
    },
    scrollToModelling(event) {
      event.preventDefault()
      document.getElementById("modelling-section").scrollIntoView({block: "center", behavior: "smooth"});
    },
    scrollToEquipment(event) {
      event.preventDefault()
      document.getElementById("equipment-section").scrollIntoView({block: "center", behavior: "smooth"});
    },
    scrollToAboutUs(event) {
      event.preventDefault()
      document.getElementById("technologies-section").scrollIntoView({block: "start", behavior: "smooth"});
    },
    scrollToFooter(event) {
      event.preventDefault()
      window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })
    }
  }
}
</script>

<style scoped lang="scss">
@import "src/assets/styles/global.scss";


#main-section {
  position: relative;
}

#header {
  background: rgba(254, 254, 254, 0.8);
  //background-color: $green-color !important;

  max-height: 150px;
  font-weight: bold;
  font-size: 20px;

  a {
    text-decoration: None;
    color: #4D4D4D;
  }

  @media screen and (max-width: $lg) {
    font-size: 15px;
  }

  @media screen and (max-width: $md) {
    font-size: 15px;
  }

  @media screen and (max-width: $sm) {
    font-size: 10px;
  }
}


.header-logo {
  height: 100%;
  min-height: 100px;
  max-height: 150px;
}

.company-name-section {
  height: calc(100vh - 150px);
  color: white;
  font-weight: bold;

  &__title {
    text-align: center;
    font-size: 80px;

    @media screen and (max-width: $md) {
      font-size: 70px;
    }

    @media screen and (max-width: $sm) {
      font-size: 45px;
    }

    @media screen and (max-width: $xs) {
      font-size: 30px;
    }

  }

  &__subtitle {
    text-align: center;
    font-size: 40px;

    @media screen and (max-width: $md) {
      font-size: 30px;
    }

    @media screen and (max-width: $sm) {
      font-size: 20px;
    }

    @media screen and (max-width: $xs) {
      font-size: 15px;
    }
  }
}

.alert {

  color: #4D4D4D;

  @media screen and (max-width: $md) {
    height: 150px;
    bottom: -75px;
  }

  @media screen and (max-width: $sm) {
    height: 100px;
    bottom: -50px;
  }

  @media screen and (max-width: $xs) {
    height: 50px;
    bottom: -25px;
  }

  $height: 130px;

  position: absolute;
  height: $height;
  background-color: $green-color;
  left: 0;
  bottom: -$height / 2;
  width: 60vw;
  max-width: calc(90vw - #{$height/2});
  display: flex;
  flex-direction: column;
  justify-content: center;


  .text-block {
    font-weight: bold;
    font-size: 25px;
    margin-left: auto;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;

    @media screen and (max-width: $md) {
      font-size: 20px;
      height: 130px;
    }

    @media screen and (max-width: $sm) {
      font-size: 15px;
      height: 100px;
    }

    @media screen and (max-width: $xs) {
      font-size: 7px;
      height: 50px;
    }
  }

  .triangle {
    position: absolute;
    content: '';
    right: -$height/2;
    top: 0;
    width: 0;
    height: 0;
    border-top: $height/2 solid transparent;
    border-left: $height/2 solid $green-color;
    border-bottom: $height/2 solid transparent;

    @media screen and (max-width: $md) {
      $height: 150px;
      border-top: $height/2 solid transparent;
      border-left: $height/2 solid $green-color;
      border-bottom: $height/2 solid transparent;
      right: -$height/2;
    }

    @media screen and (max-width: $sm) {
      $height: 100px;
      border-top: $height/2 solid transparent;
      border-left: $height/2 solid $green-color;
      border-bottom: $height/2 solid transparent;
      right: -$height/2;
    }

    @media screen and (max-width: $xs) {
      $height: 50px;
      border-top: $height/2 solid transparent;
      border-left: $height/2 solid $green-color;
      border-bottom: $height/2 solid transparent;
      right: -$height/2;
    }

  }

  @media screen and (max-width: $md) {
    font-size: 25px;
  }

}
</style>
