<template>
  <div id="about-section">
    <v-container>
      <v-row class="justify-center align-center">
        <v-col xl="3" lg="2" md="4" sm="auto" cols="auto">
          <img src="../assets/about-img.png" alt="zilant oil">
        </v-col>
        <v-col xl="auto" lg="9" md="8" class="text-block">
          <div style="position: relative" class="pa-5">
            Мы  выполняем процесс внедрения МУН на месторождении <br> от этапа лабораторных испытаний до промышленного
            <br> внедрения с последующим контролем и  актуализацией ГДМ
            <Corners/>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Corners from "@/components/Corners";
export default {
  name: "About",
  components: {Corners}
}
</script>

<style scoped lang="scss">
@import "src/assets/styles/global.scss";

#about-section {
  background-color: rgb(255, 255, 255, .8);
  padding-top: 200px;
  padding-bottom: 100px;
  min-height: 500px;
}

.text-block {
  text-align: center;
  font-size: 30px;
  position: relative;
  line-height: 52px;

  @media screen and (max-width: $lg) {
    font-size: 25px;
    margin-left: 2rem;
  }

  @media screen and (max-width: $md) {
    font-size: 22px;
    margin-left: 0;
  }

  @media screen and (max-width: $sm) {
    font-size: 18px;
    margin-left: 0;
  }
}
</style>
