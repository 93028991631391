<template>
  <div id="modelling-section">
    <v-container>
      <v-row class="modeling-title justify-center">
        МОДЕЛИРОВАНИЕ
      </v-row>
      <v-row class="justify-center">
        <v-col class="description justify-center" cols="7">
          За плечами наших специалистов более десядка проектов по подбору и моделированию методов добычи жидких
          углеводородов.
          <Corners/>
        </v-col>
      </v-row>
      <v-row align="center" justify="center" class="justify-center">
        <v-col cols="10">
          <img class="modeling-img" src="../assets/modelling-image.svg" alt="">
        </v-col>
      </v-row>
      <v-row class="justify-center mt-10">
        <TechButton @click="downloadDoc"/>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Corners from "@/components/Corners";
import TechButton from "@/components/TechButton";

export default {
  name: "Modelling",
  components: {TechButton, Corners},
  methods: {
    async downloadDoc() {
      await fetch(`https://${location.host}/getDoc2`, {
        method: 'GET'
      }).then(response => {
        response.blob().then(blob => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.style.display = 'none';
          a.href = url;
          a.download = 'documentation.pdf';
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        })
      });
    }
  }
}
</script>

<style scoped lang="scss">
@import "src/assets/styles/global.scss";

#modelling-section {
  padding-top: 100px;
  padding-bottom: 100px;
  background-color: rgb(255, 255, 255, .8);
}

.modeling-title {
  font-size: 50px;
  color: #256B48;
  font-weight: 700;
  margin-bottom: 3rem;
  text-align: center;
}

.description {
  position: relative;
  font-size: 30px;
  text-align: justify;
  padding: 2rem;

  @media screen and (max-width: $lg) {
    font-size: 25px;
  }

  @media screen and (max-width: $md) {
    font-size: 22px;
  }

  @media screen and (max-width: $sm) {
  }
}

.modeling-img {
  width: 100%;
}

</style>
