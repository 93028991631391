<template>
  <div>
    <div class="corner1"></div>
    <div class="corner2"></div>
  </div>
</template>

<script>
export default {
  name: "Corners"
}
</script>

<style scoped lang="scss">
.corner1 {
  position: absolute;
  width: 30px;
  height: 30px;
  top: 0;
  left: 0;
  border-left: 4px solid black;
  border-top: 4px solid black;
}

.corner2 {
  position: absolute;
  width: 30px;
  height: 30px;
  bottom: 0;
  right: 0;
  border-right: 4px solid black;
  border-bottom: 4px solid black;
}
</style>
