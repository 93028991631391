<template>
  <v-app style="
    background-color:transparent;
  ">
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'App',

  data: () => ({
    //
  }),
  mounted() {
    window.addEventListener('scroll', () => {
      const yPos = -(document.scrollingElement.scrollTop / 10); // вычисляем коэффициент
      document.body.style.backgroundPosition = 'center ' + yPos + 'px'
    });
  }
};
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');




* {
  font-family: 'Montserrat', sans-serif;
}

body {
  background: url("./assets/parallax-background.jpg") center 0 repeat-y fixed;
  //background-size: cover;
  background-size: 300%;
}

.v-main__wrap {
  background-color: transparent !important;
}
</style>
